import "./claim.scss"
import React, {useContext, useState, useEffect} from "react";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import {useNavigate} from "react-router";
import {bottomToast} from "../../utils/toast";
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import FooterWarning from '../../components/footerWarning/footerWarning';
import ProgressLoading from '../../components/progressLoading/ProgressLoading';
import BlueQuestionMark from './assets/blue.png'

export default function Claim(props){
    const { style, theme } = props;
    const [code, setCode] = useState();
    const {state} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const handleClaim = () => {
        if(!state.isLogin){
            navigate("/login");
        }else{
            try {
                fetch(
                    serverAddress + "/nfts/redemption",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({"collectionId": 6, "code": code })
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nft = result.data.nfts;
                            let {name,tokenId,thumbnailURL,collectionInfo, displayURL} = nft[0];
                            localStorage.setItem('desc', collectionInfo.description)
                            localStorage.setItem('name', name)
                            navigate("/congrats?tokenId=" + tokenId + "&url=" + displayURL);
                        }else if(result.returnCode === responseCode.redemptionCodeInvalid){
                            bottomToast("请输入正确的领取码");
                        }else if(result.returnCode === responseCode.redemptionCodeAlreadyUsed){
                            bottomToast("该兑换码已使用");
                        }else if(result.returnCode === responseCode.claimAmountExceeded){
                            bottomToast("领取数量超过限制");
                        }else if(result.returnCode === "24000"){
                            bottomToast("请输入正确的领取码");
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }

    }
    return(
        <div className={`claim-page ${theme.main==='dark'?'claim-page-dark-theme':''}`}>
            {/* <ProgressLoading text={'努力铸造中...'} isShowing={loading}/> */}
            <ArrowIcon/>
            <div className={`card-container ${theme.main==='dark'?'card-container-dark-theme':''}`}>
                <img className='question-mark' src={BlueQuestionMark} alt=""/>
            </div>
            <div className='input-container'>
                <input className={`input-code ${theme.main==='dark'?'input-code-dark-theme':''}`} size="1"
                    onChange={(e) => {setCode(e.target.value)}}
                    onClick={(e) => {e.target.scrollIntoView({inline: "start"})}}
                    placeholder="输入领取码"/>
            </div>
            <button className='claim-button' onClick={handleClaim} style={{background:style.buttonBackground}}>立即领取</button>
            <FooterWarning />
        </div>
    )
}