import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "./nftCard.scss";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Autoplay } from "swiper";

SwiperCore.use([FreeMode, Autoplay]);

export default function NftCard(props){
    const {nftInfo, handleSlideChange} = props;

    return(
      <div style={{ height: '420px', width: "100%", position: 'absolute', marginTop: '120px'}}>
        <Swiper slidesPerView={'auto'}
          spaceBetween={23} centeredSlides={true} autoplay={nftInfo.length >1 ? {delay: 1000, disableOnInteraction: false}: false} loop={nftInfo.length>1}
          className="home-swiper"
          onSlideChange={handleSlideChange}
        >
          {nftInfo.map((nft,index)=>{
            return (
              <SwiperSlide className="nft-card" key={index}>
                <img className="nft-image" mode="widthFix" src={nft.image} alt=""/>
                <div className="nft-author-container">
                  <img className="avatar" src={nft.avatar} alt=""/>
                  <div className="author-name">{nft.author}</div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    )

}