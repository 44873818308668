import "./nftlist.scss"
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import MenuIcon from "../../components/menuIcon/menuIcon";
import FooterWarning from '../../components/footerWarning/footerWarning';
import bluePlus from './assets/blue-plus.png'
import redPlus from './assets/red-plus.png'
import purplePlus from './assets/purple-plus.png'
import defaultNftImage from './assets/default-nft-image.png'

export default function NFTlist(props){
    const { style, AddFrame, CardFrame, theme } = props;
    const [list, setList] = useState([]);
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();
    const goToClaim = () => {
        navigate('/claim')
    }

    const goToDetail = (e) => {
        navigate('/detail/'+e.target.id)
    }

    // mockdata
    const nftList = [
        {
            id: 1,
            image: defaultNftImage,
            author: '咖啡科技',
            name: '独家发售设计天团NFT'
        },
        {
            id: 2,
            image: defaultNftImage,
            author: '咖啡科技',
            name: '独家发售设计天团NFT'
        },
        {
            id: 3,
            image: defaultNftImage,
            author: '咖啡科技',
            name: '独家发售设计天团NFT'
        }
    ]

    useEffect(() => {
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress + "/myNFTs?site=cnpc&pageSize=100&pageNum=1",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken},
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nfts = result.data.nfts;
                            setList(nfts);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.accessToken])

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        // <div id="list-container">
        //     <div id="content-container">
        //         {
        //              list.map((nft,index) => {
        //                 return (
        //                     <Frame height={style.frame.height} width={style.frame.width}
        //                            contentWidth={style.frame.content.width} contentHeight={style.frame.content.height}
        //                            backgroundImage={CardFrame} contentImage={nft.displayURL || nftImage}
        //                            nftName={nft.name || '数字藏品名字' } nftTokenID={nft.id || '123'} key={index}/>
        //                 )
        //              })
        //         }
        //          <Frame height={style.frame.height} width={style.frame.width}
        //                contentWidth={style.addFrame.content.height} contentHeight={style.addFrame.content.width}
        //                backgroundImage={CardFrame} contentImage={AddFrame} onClick={goClaim}
        //                nftName={'添加数字藏品'}/>
        //     </div>
        //     <div className='duty-container'>
        //         <DutyFree />
        //     </div>
        // </div>
        <div className={`list-page ${theme==='dark'?'list-page-dark-theme':''}`}>
            <MenuIcon/>
            <div className='content-container'>
                {list.map((nft, index) => {
                    return (
                        <div className='detail-container' id={nft.id} key={index} onClick={goToDetail}>
                            <img className='nft-image' id={nft.id}  src={nft.thumbnailURL} alt=''/>
                            <div className={`list-nft-author ${theme==='dark'?'list-nft-author-dark-theme':''}`} id={nft.id} >{nft.publisher}</div>
                            <div className={`list-nft-name ${theme==='dark'?'list-nft-name-dark-theme':''}`} id={nft.id} >{nft.name}</div>
                        </div>
                    )
                })}
                <div className='detail-container'>
                    <div className={`plus-button ${theme==='dark'?'plus-button-dark-theme':''}`} onClick={goToClaim}>
                        <img className='plus-image' src={bluePlus} alt=''/>
                    </div>
                </div>
            </div>
            <FooterWarning/>
        </div>
    )
}