import "./detail.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { serverAddress } from "../../App";
import { responseCode } from "../../config";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import defaultAvatar from '../../assets/fuel-avatar.png'
import copy from 'copy-to-clipboard';
import { bottomToast } from "../../utils/toast";

export default function Detail(props) {
    const { theme, style } = props;

    const navigate = useNavigate();
    const { id } = useParams();

    const [nftInfo, setInfo] = useState({});

    const getSubStr = (str) => {
        if(str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const goToPoster = () => {
        navigate("/poster/" + id);
    }

    const copyAddress = () => {
        copy(nftInfo.address)
        bottomToast('复制成功');
    }

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId } = result.data;
                        setInfo({ avatar: defaultAvatar, author: collectionInfo.publisher,ownedAt, id, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator: creator, tokenId });
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [id]);

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId } = result.data;
                        setInfo({ avatar: defaultAvatar, author: collectionInfo.publisher,ownedAt, id, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator: creator, tokenId });
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, []);

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return (
        <div className={`detail-page ${theme==='dark'?'detail-page-dark-theme':''}`}>
            <ArrowIcon />
            <div className={`detail-nft-title ${theme==='dark'?'detail-nft-title-dark-theme':''}`}>{nftInfo.name}</div>
            <div className={`detail-nft-id ${theme==='dark'?'detail-nft-id-dark-theme':''}`}># {nftInfo.tokenId}</div>
            <div className={`top-nft-info-container ${theme==='dark'?'top-nft-info-container-dark-theme':''}`}>
                <img className="inside-nft-img" src={nftInfo.image} alt=""/>
                <div className="nft-card-bottom-container">
                    <img className="detail-nft-avatar" src={nftInfo.avatar} alt=""/>
                    <div className={`detail-nft-author-name ${theme==='dark'?'detail-nft-author-name-dark-theme':''}`}>{nftInfo.author}</div>
                </div>
            </div>
            <div className={`bottom-nft-info-container ${theme==='dark'?'bottom-nft-info-container-dark-theme':''}`}>
                <div className={`bottom-info-title ${theme==='dark'?'bottom-info-title-dark-theme':''}`}>藏品信息</div>
                <div className="bottom-detail-into-container">
                    <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>藏品名称</div>
                    <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.name}</div>
                </div>
                <div className="bottom-detail-into-container">
                    <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>合约地址</div>
                    <div className={`detail-info-content address-info-container ${theme==='dark'?'detail-info-content-dark-theme':''}`} style={{color: style.addressColor}} onClick={copyAddress}>
                        <div className="address-copy-button"></div>
                        <div>{nftInfo.address?getSubStr(nftInfo.address):''}</div>
                    </div>
                </div>
                <div className="bottom-detail-into-container">
                    <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>发行方</div>
                    <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.publisher?getSubStr(nftInfo.publisher):''}</div>
                </div>
                <div className="bottom-detail-into-container">
                    <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>创作作者</div>
                    <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.creator?getSubStr(nftInfo.creator):''}</div>
                </div>
                <div className="bottom-detail-into-container">
                    <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>创建时间</div>
                    <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.ownedAt?nftInfo.ownedAt.toString().substr(0,10):''}</div>
                </div>
            </div>
            <div className={`detail-page-footer-warning ${theme==='dark'?'detail-page-footer-warning-dark-theme':''}`}>本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。</div>
            <button onClick={goToPoster} className="go-to-poster-button" style={{background: style.buttonBackground}}>生成海报</button>
        </div>
    );
}
