import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import ProgressLoading from '../../components/progressLoading/ProgressLoading';
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import "./poster.scss";
import * as htmlToImage from "html-to-image";
import QrCode from './assets/qrcode.png';
import {bottomToast} from "../../utils/toast";
import darkDownloadIcon from './assets/download-icon-dark.png'
import lightDownloadIcon from './assets/download-icon-light.png'

export default function Poster(props){
    const{ theme } =props;

    const { state } = useContext(AuthContext);
    const stageRef = useRef();

    const navigate = useNavigate();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);

    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [publisher, setPublisher] = useState();
    const [creator, setCreator] = useState();
    const [address, setAddress] = useState();
    const [total, setTotal] = useState();
    const params = useParams();

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        parentDiv.innerHTML ='';
        const result = document.createElement("img");
        result.style.width = '100%';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.src = url;
        result.mode = 'widthFix'
        stageRef.current.appendChild(result);
        stageRef.current.scrollTo(0,0);
        result.onload = () => {
            setLoaded(true);
        };
    }

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const setInformation=(res)=>{
        setTokenId(res.data.tokenId);
        setOwner(res.data.ownedAt);
        setName(res.data.name);
        setPublisher(res.data.collectionInfo.publisher);
        setCreator(res.data.creator)
        setAddress(res.data.collectionInfo.contractAddress);
        setTotal(res.data.collectionInfo.amount)
    }

    const errorToast =() => {
        bottomToast('网络波动，请重试', 'center',()=>{
            navigate(`/detail/${params.id}`)
        })
    }



    useEffect(() => {
            if (state.isLogin) {
                fetch(serverAddress + "/nft/"+ params.id, { method: "GET" })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((res) => {
                                if (res.returnCode === "20000") {
                                    let url = res.data.thumbnailURL;
                                    let nftImg = document.createElement("img");
                                    nftImg.crossOrigin = "anonymous";
                                    nftImg.src = url;
                                    nftImg.onload = function(){
                                        setNftLoaded(true);
                                        setNft(nftImg.src);
                                    }
                                    setInformation(res)
                                }
                            }).catch(()=>errorToast());
                        }
                    });
            }
        }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded) {
            setTimeout(() => {
                fetch(serverAddress + "/poster/isUploaded?nftId="+ params.id, {
                    method:"GET",
                    headers: {"accessToken": state.accessToken}}).then((res) => {
                    if(res.status === 200){
                        res.json().then( r => {
                            if(r.returnCode === "20000"){
                                if(r.data.length === 0){
                                    htmlToImage.toBlob(stageRef.current).then(() => {
                                        htmlToImage.toBlob(stageRef.current).then(() => {
                                            htmlToImage.toBlob(stageRef.current).then(() => {
                                                htmlToImage.toBlob(stageRef.current).then((blob) => {
                                                    let img = new File([blob],'poster.png');
                                                    let formData = new FormData();
                                                    formData.append('file', img);
                                                    fetch(serverAddress + "/poster/uploadOss?nftId=" + params.id,{
                                                        method:"POST",
                                                        headers: {"accessToken": state.accessToken},
                                                        body: formData
                                                    }).then((res) => {
                                                        if(res.status === 200){
                                                            res.json().then(r => {
                                                                if(r.returnCode === "20000"){
                                                                    displayPoster(r.data);
                                                                }
                                                            })
                                                        }
                                                    }).catch(()=>errorToast())
                                                });
                                            });
                                        })
                                    })
                                }else{
                                    displayPoster(r.data);
                                }
                            }
                        })
                    }
                }).catch(()=> errorToast())
            },500);
        }
    }, [nftLoaded]);

    useEffect(() => {
        if(!loaded){
            document.body.style.overflow='hidden';
        }else{
            document.body.style.overflow='';
        }
    },[loaded])


    return(
        <div className="poster-container">
            <ProgressLoading text={'海报生成中...'} isShowing={!loaded}/>
            <ArrowIcon />
            <img className="download-icon" onClick={()=>bottomToast('长按图片下载海报')} src={`${theme==='dark'?darkDownloadIcon:lightDownloadIcon}`} alt=''/>

            <div ref={stageRef} id='stageDiv' className={`stageDiv ${theme==='dark'?'stageDiv-dark-theme':''}`}>
                <div className={`nft-poster-title ${theme==='dark'?'nft-poster-title-dark-theme':''}`}>{name}</div>
                <div className={`nft-poster-subtitle ${theme==='dark'?'nft-poster-subtitle-dark-theme':''}`}>{`#${tokenId}/${total}`}</div>
                <img className="nft-poster-image" src={nft} alt=""/>
                <div className={`poster-bottom-info-container ${theme==='dark'?'poster-bottom-info-container-dark-theme':''}`}>
                    <div className={`bottom-info-title ${theme==='dark'?'bottom-info-title-dark-theme':''}`}>数字藏品信息</div>
                    <div className="bottom-info-sub-container" style={{marginTop:'10px'}}>
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>发行方</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{getSubStr(publisher)}</div>
                    </div>
                    <div className="bottom-info-sub-container">
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>创建时间</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{owner?owner.toString().substr(0,10):''}</div>
                    </div>
                    <div className="bottom-info-sub-container">
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>创作作者</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{getSubStr(creator)}</div>
                    </div>
                    <div className="bottom-info-sub-container">
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>合约地址</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{getSubStr(address)}</div>
                    </div>
                </div>
                <div className="poster-bottom-qrcode-container">
                    <div className="qrcode-container">
                        <img className="bottom-left-qrcode" src={QrCode} alt=""/>
                        <div className={`qrcode-info ${theme==='dark'?'qrcode-info-dark-theme':''}`}>
                            <div className="qrcode-text" style={{width: '140px', textAlign: 'start'}}>扫描二维码</div>
                            <div className="qrcode-text" style={{width: '140px', textAlign: 'start'}}>了解更多信息</div>
                        </div>
                    </div>
                    <div className="logo-group"></div>
                </div>
            </div>
        </div>
    );
}
