import './menuIcon.scss'
import menuIcon from "./assets/menu-icon-light.png"
import {useNavigate, useLocation} from "react-router-dom";
import { useState, useContext } from "react";
import {AuthContext} from "../../App";
import useLogout from "../../utils/useLogout";

export default function MenuIcon () {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(AuthContext);
  const logout = useLogout();

  const [menuDisplay, setMenuDisplay] = useState(false);

  const handleLogout = () => {
    logout().then((r) => {
        if(r) navigate('/login');
    })
}

  const showMenu = () => {
    setMenuDisplay(true)
  }
  const closeMenu = () => {
    setMenuDisplay(false)
  }

  const goToHome = () => {
    if (location.pathname === '/') {
      setMenuDisplay(false)
    } else {
      navigate('/')
    }
  }

  const goToAbout = () => {
      navigate('/about')
  }

  const goToLogin = () => {
    navigate('/login')
  }

  const goToMyNft = () => {
    if (location.pathname === '/list') {
      setMenuDisplay(false)
    } else {
      navigate('/list')
    }
  }

  return (
    <>
    <img className="menu-icon" src={menuIcon} alt='' onClick={showMenu}/>
    {menuDisplay ? <div className="menu-modal" onClick={closeMenu}></div>:null}
    {
      menuDisplay ?
      <div className={`menu-buttons-container ${state.isLogin?'buttons-container-4':''}`}>
        <div className="nav-button" onClick={goToHome}>首页</div>
        {state.isLogin?<div className="nav-button" onClick={goToMyNft}>我的藏品</div>: null}
        <div className="nav-button" onClick={goToAbout}>关于藏品</div>
        {!state.isLogin?<div className="nav-button" onClick={goToLogin}>登录</div>: null}
        {state.isLogin?<div className="nav-button" onClick={handleLogout}>退出登录</div>: null}
      </div> : null
    }
    </>
  )
}